import React from 'react'
import PackageDetail from './PackageDetail'

export default function North() {
  return (
    <>
      <section className="padding3">
        <div className="container">
            <div className='mb-4 text-md-start text-center'>
                <h4 className='fw-bold'>MPA Northern Areas Packages</h4>
            </div>
        <PackageDetail
          card1_image="static/images/pkg1.webp"
          card1_title="Summer Special Naran and Kaghan Trip"
          card1_days="7 Days"
          price1="PKR 13,500"
          card1_availability="2 Pakages Available"
          description1="Explore Naran's serene lakes and lush meadows, and Kaghan's verdant valleys and vibrant flowers. Discover majestic peaks, warm hospitality, and a blend of rich cultural heritage with natural beauty."
          pdf1="static/images/7daysNaran.pdf"

          card2_image="static/images/pkg2.webp"
          card2_title="Summer Special Hunza & Skardu"
          card2_days="12 Days"
          price2="PKR 32,000"
          card2_availability="1 Pakages Available"
          description2="Explore Skardu's serene lakes and rugged mountains, and Minimarg's lush, flower-filled valleys. Discover Hunza's majestic peaks and warm hospitality for a perfect escape into nature's splendor."
          pdf2="static/images/hunza&skardu.pdf"

          card3_image="static/images/pkg3.webp"
          card3_title="Swat, Kashmir, Kaghan From Hyderabad"
          card3_days="12 Days"
          price3="PKR 32,000"
          card3_availability="5 Pakages Available"
          description3="Explore the stunning landscapes of Kashmir, Kaghan, and Swat with serene lakes, lush meadows, and vibrant valleys. Enjoy majestic peaks, warm hospitality, and rich cultural heritage for a perfect blend of adventure and tranquility."
          pdf3="static/images/swat&naran.pdf"
          
          />
        </div>
      </section>
    </>
  )
}

import React from 'react'
import Popular from './Popular'


export default function Services() {
  return (
    <>
    <section className='padding3 bg-service d-flex justify-content-center align-items-center'>
        <div className="container">
            <div className='text-center'>
                <h1 className=" text-white">Our Services</h1>
            </div>
        </div>
    </section>
      <section className="padding">
        <div className="container">
       
           
            <div className='text-center'>
                <h1 className="">Our Popular Destinations</h1>
            </div>
            <div className="row mt-5">
               <Popular 
                image='static/images/5.webp' 
                title='Makkah'
                description='Discover the spiritual heart of Makkah, the premier destination for Umrah pilgrims. Experience the profound tranquility of the Holy Mosque, home to the Kaaba, where millions gather in devotion each year. Makkah offers a sacred retreat for reflection and prayer, enriched by its historic and spiritual significance. Embrace the transformative journey of Umrah in this revered city, resonating with deep spiritual heritage and serenity.'/>
                
               <Popular image='static/images/madina.png' title='Madina'  description="Experience the serene allure of Madinah, a sacred haven for pilgrims seeking spiritual enrichment. Home to the Prophet's Mosque, this revered city offers a tranquil sanctuary for prayer and reflection. Explore the historical and spiritual significance of Madinah, where every moment is steeped in deep religious heritage. Embrace a journey of peace and devotion in Madinah, a place where spirituality and history harmoniously converge." />

                <Popular image='static/images/4.webp' title='Hunza Valley'  description="Discover the majestic beauty of Hunza Valley, a gem in Pakistan’s northern region. Surrounded by towering peaks and verdant landscapes, Hunza offers a serene escape into nature's splendor. Wander through charming villages and vibrant markets, soaking in the rich cultural heritage and warm hospitality. Whether seeking adventure or tranquility, Hunza Valley promises an unforgettable journey." />

                 <Popular image='static/images/6.webp' title='Naltar Valley'  description="Explore the pristine beauty of Naltar Valley, a hidden gem in Pakistan's northern landscapes. Renowned for its crystal-clear lakes and lush forests, Naltar offers a serene retreat amidst stunning natural beauty. Immerse yourself in the tranquil environment, surrounded by snow-capped peaks and vibrant flora. Whether you're seeking adventure or a peaceful escape, Naltar Valley promises an unforgettable experience." />
               
           </div>
        </div>
      </section>
    </>
  )
}

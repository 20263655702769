import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';

export default function Navbar() {

    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);
  
    const handleClick = (path) => {
      setActiveLink(path);
    };

    const getLinkClass = () => {
        if (activeLink === '/North' || activeLink === '/Umrah') {
          return 'nav-item droplink active-link';
        } 
        
        return 'nav-item droplink link2';
        
    };

    // const getLinkClassMob = () => {
    //     if (activeLink === '/North' || activeLink === '/Umrah') {
    //       return 'nav-link mob-active-link';
    //     } 
        
    //     return 'nav-link mob-active-link';
        
    // };

    useEffect(() => {
        const navbar = document.querySelector('.navbar-nav-ul');
        const bars = document.querySelector('.bars');
        const body = document.querySelector('body');
        const overlay = document.querySelector('.overlay');
        const close = document.querySelector('.close-btn');
    
        const openNavbar = () => {
          if (navbar && overlay && body) {
            navbar.style.left = '0px';
            overlay.style.visibility = 'visible';
            navbar.style.transition = 'all 0.5s ease-in-out';
            overlay.style.transition = 'all 0.5s ease-in-out';
            body.style.overflow = 'hidden';
          }
        };
    
        const closeNavbar = () => {
          if (navbar && overlay && body) {
            navbar.style.left = '-100%';
            overlay.style.visibility = 'hidden';
            navbar.style.transition = 'all 0.5s ease-in-out';
            overlay.style.transition = 'all 0.5s ease-in-out';
            body.style.overflow = 'auto';
          }
        };

        const alllinks = document.querySelectorAll('.nav-link'); 

        alllinks.forEach((link)=>{
            link.addEventListener('click', function(event){
                if (navbar && overlay && body) {
                    navbar.style.left = '-100%';
                    overlay.style.visibility = 'hidden';
                    navbar.style.transition = 'all 0.5s ease-in-out';
                    overlay.style.transition = 'all 0.5s ease-in-out';
                    body.style.overflow = 'auto';
                }
            })
        });
    
        if (bars) bars.addEventListener('click', openNavbar);
        if (close) close.addEventListener('click', closeNavbar);
    
        // Cleanup event listeners on component unmount
        return () => {
          if (bars) bars.removeEventListener('click', openNavbar);
          if (close) close.removeEventListener('click', closeNavbar);
        };
      }, []);

  return (
    <>
    <div className="wp-icon">
        <a href="https://api.whatsapp.com/send/?phone=%2B923367287283&text&type=phone_number&app_absent=0">
            <img src="static/images/wp.webp" alt="" className='img-fluid' width={'80px'}></img>
        </a>
    </div>
    <div className="overlay">
        <div className="spinner-border text-light" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    </div>
    <div className="navbar-nav-ul d-lg-none d-block">
         <ul className="navbar-nav mb-2 mb-lg-0 mx-lg-auto mx-0">
                    <li className="nav-item text-end">
                        <Link className="close-btn">
                            <i className="fa-solid fa-xmark fs-4"></i>
                        </Link>
                    </li>
                    <li className="nav-item ">
                        <Link className={activeLink === '/' ? 'nav-link mob-active-link':'nav-link'} onClick={()=> handleClick('/')} to="/">Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link className={activeLink === '/' ? 'nav-link mob-active-link ':' nav-link '} onClick={()=> handleClick('/North')} to="/North">North Pakistan</Link>
                        <Link className={activeLink === '/' ? 'nav-link mob-active-link':'nav-link '} onClick={()=> handleClick('/Umrah')} to="/Umrah">Hajj & Umrah</Link>
                  </li>
                    
                    <li className="nav-item ">
                        <Link className={activeLink === '/PlanMyTrip' ? 'nav-link mob-active-link':'nav-link'}
                        onClick={() => handleClick('/PlanMyTrip')} to="/PlanMyTrip" >Plan My Trip</Link>
                    </li>
                    <li className="nav-item ">
                        <Link className={activeLink === '/about' ? 'nav-link mob-active-link':'nav-link'}
                        onClick={() => handleClick('/about')} to="/about" >About</Link>
                    </li>
                    <li className="nav-item ">
                        <Link className={activeLink === '/services' ? 'nav-link mob-active-link':'nav-link'}
                        onClick={() => handleClick('/services')} to="/services" >Services</Link>
                    </li>
                    <li className="nav-item ">
                        <Link className={activeLink === '/contact' ? 'nav-link mob-active-link':'nav-link'}
                        onClick={() => handleClick('/contact')} to="/contact" >Contact</Link>
                    </li>
                </ul>
    </div>


    <nav className="navbar navbar-expand-lg navbar-light bg-white">
        
        <div className="container">
            
            <Link className="navbar-brand" to="/">
                <img src="static/images/logo.png" alt="" className='img-fluid'></img>
            </Link>
           
            <div className='text-end d-flex justify-content-end d-lg-none bars'>
                <img src="static/images/bars.svg" alt="" className='img-fluid' width={'20px'}></img>
            </div>
           
                <ul className="navbar-nav mb-2 mb-lg-0 mx-lg-auto mx-0 d-lg-inline-flex d-none">
                    <li className={activeLink === '/' ? 'nav-item active-link' : 'nav-item link'}
                     onClick={() => handleClick('/')}
                     >
                        <Link className='nav-link' to="/" >Home</Link>
                    </li>
                    <li className={getLinkClass()}
                     >
                    <span className="nav-link">
                        Destinations <i className="fa-solid fa-angle-down my-auto"></i>
                    </span>
                    <ul className="dropdown-menu">
                        <li className='dropdown-item'
                     onClick={() => handleClick('/North')}
                        ><Link className="dropdown-item border-bottom" to="/North">North Pakistan</Link></li>
                        <li className='dropdown-item'
                     onClick={() => handleClick('/Umrah')} ><Link className="dropdown-item  "  to="/Umrah">Hajj & Umrah</Link></li>
                    </ul>
                    </li>
                    
                    <li  className={activeLink === '/PlanMyTrip' ? 'nav-item active-link' : 'nav-item link2'}
                     onClick={() => handleClick('/PlanMyTrip')}>
                        <Link className="nav-link" to="/PlanMyTrip" >Plan My Trip</Link>
                    </li>
                    <li className={activeLink === '/about' ? 'nav-item active-link' : 'nav-item link'}
                     onClick={() => handleClick('/about')}>
                        <Link className="nav-link" to="/about" >About</Link>
                    </li>
                    <li className={activeLink === '/services' ? 'nav-item active-link' : 'nav-item link2'}
                     onClick={() => handleClick('/services')}>
                        <Link className="nav-link" to="/services" >Services</Link>
                    </li>
                    <li className={activeLink === '/contact' ? 'nav-item active-link' : 'nav-item link'}
                     onClick={() => handleClick('/contact')}>
                        <Link className="nav-link" to="/contact" >Contact</Link>
                    </li>
                </ul>
                <div className='d-flex d-lg-flex d-none'>
                    <Link to="/contact" className="btn-3">Get Started</Link>
                </div>
               
   
        </div>

    </nav>
    </>
  )
}

import React from 'react'
import { Link } from 'react-router-dom'
export default function Popular(props) {
  return (
    <>
                <div className="col-lg-4 mb-4 mt-lg-0 mt-4 text-lg-start text-center">
                    <img src={props.image} alt="" className='img-fluid' style={{'height':'300px','objectFit':'cover','borderRadius':'20px'}}></img>
                </div>
                <div className="col-lg-8 mt-lg-auto text-lg-start text-center my-auto">
                        <h4 className="">{props.title}</h4>
                        <p>
                            {props.description}
                        </p>
                       
                        <div className='mt-4'>
                            <Link to="/contact" className="btn-3 fs-14 px-5 py-2">
                                Book Now
                            </Link>
                        </div>
                </div>
    </>
  )
}

import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <>
        <footer className='padding bg-blue'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-lg-0 mb-3">
                        <div className='text-md-start text-center' data-aos="zoom-in">
                            <Link to="/">
                                <img src="static/images/logo.png" alt="" className='img-fluid'></img>
                            </Link> 
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 mb-lg-0 mb-3">
                        <h4 className="text-orange fw-bold">
                            MPA TRAVELS & TOURS
                        </h4>
                        <p className='text-white'>
                        Embark on a discovery of Pakistan's rich cultural tapestry and breathtaking landscapes with MPA Travels and Tours. Our passion is to showcase the beauty and warmth of Pakistan, ensuring every journey is an authentic and memorable exploration.
                        </p>
                    </div>  

                    <div className="col-lg-3 col-md-6 mb-lg-0 mb-3">
                        <h4 className="text-orange fw-bold">
                            Quick Links
                        </h4>
                        <ul className='p-0 list-unstyled footer-ul'>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/North">North Pakistan</Link></li>
                            <li><Link to="/PlanMyTrip">Plan My Trip</Link></li>
                            <li><Link to="/services">Services</Link></li>
                            <li><Link to="/about">About</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </div>  

                    <div className="col-lg-3 col-md-6 mb-lg-0 mb-3">
                        <h4 className="text-orange fw-bold">
                            Our Address
                        </h4>
                        <p className='text-white mb-2'>
                            First Floor Raeesa Apartment Unit No.07 Near Maajee Hospital Latifabad Hyderabad
                        </p>
                        <p className='mb-2'>
                            <a href="https://api.whatsapp.com/send/?phone=%2B923367287283&text&type=phone_number&app_absent=0" className='text-white'>
                            (+92) 336-7287283
                            </a>
                        </p>
                        <p className='mb-2'>
                            <a href="mailto:MPAtravelsAndTours@gmail.com" target='_blank' rel='noreferrer' className='text-white'>
                                MPAtravelsAndTours@gmail.com 
                            </a>
                        </p>
                        <p className='mb-2'>
                            <Link className='text-white'>
                             11:00 am - 8:00 pm 
                            </Link>
                        </p>
                        <p className='mb-2' >
                            <div>
                            <a href="https://www.facebook.com/login/?next=https%3A%2F%2Fwww.facebook.com%2Fmpatravelsandtours%2F" target='_blank' rel='noreferrer'>
                                <img src="static/images/fb.webp" alt="" className='img-fluid' width={'35px'} ></img>
                            </a>
                            <a href="https://www.instagram.com/mpatravelsandtours/" target='_blank' rel='noreferrer'>
                                <img src="static/images/insta.webp" alt="" className='img-fluid' width={'35px'} ></img>
                            </a>
                            <a href="https://www.tiktok.com/@mpatravelsandtours" target='_blank' rel='noreferrer'>
                                <img src="static/images/tiktok.webp" alt="" className='img-fluid' width={'35px'} ></img>
                            </a>
                            <a href="mailto:MPAtravelsAndTours@gmail.com " target='_blank' rel='noreferrer' className='text-white'>
                                @MPATravelsAndTours
                            </a>
                            </div>
                        </p>
                        
                    </div>  

                </div>
                <div className="footer bg-blue row py-4">
                        <div className="col-md-6 text-white text-md-start text-center">
                            <p>&copy; 2021-2024 MPA Travels and Tours All Rights Reserved.</p>    
                        </div>
                        <div className="col-md-6 text-white text-end text-md-start text-center">
                            <p>Designed and Developed By <a href="https://mediaproductionart.com/" className='text-white'>MediaProductionArt.com</a></p>    
                        </div>
                </div>
            </div>
        </footer> 
    </>
  )
}

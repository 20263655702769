import React from 'react'
import PackageDetail from './PackageDetail'


export default function Umrah() {
  return (
    <>
       <section className="padding3">
        <div className="container">
            <div className='mb-4 text-md-start text-center'>
                <h4 className='fw-bold'>MPA Hajj & Umrah Packages</h4>
            </div>
        <PackageDetail
          card1_image="static/images/umrah1.webp"
          card1_title="Muharram Umrah Package"
          card1_days="20 Days"
          card1_availability="2 Pakages Available"
          price1="PKR 225,000"
          description1="Embark on a profound pilgrimage with our Muharram Umrah package, offering exclusive benefits and heartfelt services. Embrace the sacred journey during this revered month with exceptional care and comfort."

          card2_image="static/images/umrah2.webp"
          card2_title="Rabi ul Awwal Umrah Package"
          card2_days="21 Days"
          card2_availability="1 Pakages Available"
          price2="PKR 250,000"
          description2="Experience the spiritual journey of a lifetime with our exclusive Rabi ul Awwal Umrah package. Enjoy special offers and personalized services as you embark on this sacred pilgrimage during the blessed month."


          card3_image="static/images/umrah3.webp"
          card3_title="Muharram Umrah Package"
          card3_days="21 Days"
          card3_availability="5 Pakages Available"
          price3="PKR 225,000"
          description3="Embark on a profound pilgrimage with our Muharram Umrah package, offering exclusive benefits and heartfelt services. Embrace the sacred journey during this revered month with exceptional care and comfort."

          />
        </div>
      </section>
    </>
  )
}

import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';

export default function PackageDetail(props) {
  return (
    <>
      <div className="row">
        <div className="col-lg-8">
        <div className="cardd row p-3 ">

        <div className="package-img my-auto  col-md-4" >
            <LazyLoadImage
                alt=''
                effect="blur"
                wrapperProps={{
                    // If you need to, you can tweak the effect transition using the wrapper style.
                    style: {transitionDelay: "1s"},
                }}
                src={props.card1_image} 
                className='shadow img-fluid'
                />
                {/* <img src= alt="" className='img-fluid shadow'></img> */}
            </div>
            <div className='col-md-8 mt-md-0 mt-3 p-3'>
                <div className='d-flex justify-content-between align-items-start'>
                    <h4 className='fw-bold w-75'>
                        {props.card1_title}  
                    </h4>
                    <span className='px-3 py-1 w-25 text-white fs-12 bg-yellow  text-center'>
                        {props.card1_days}
                    </span>
                </div>
                <p className="fs-12 text-secondary">{props.card1_availability}</p>
                <p className="fs-14 fw-bold text-orange">{props.price1}</p>
                <p className='fs-14'>{props.description1}</p>
                <div className='row mt-3'>
                    <div className="col-7">
                        <Link to="/contact" className="btn-1 p-2 fs-14 px-md-4 px-3">Book Now</Link>
                    </div>
                    <div className="col-5 text-end">
                        {props.pdf1 != null ? (<a href={props.pdf1} target='_blank' className="btn-1 p-2 fs-14 px-md-4 px-3" rel='noreferrer'>Explore</a>) : (<span target='_blank' className="btn-1 p-2 fs-14 px-md-4 px-3 disabled-link" rel='noreferrer'>Explore</span>)}
                    </div>
                </div>
                
            </div>
            </div>
        </div>

        <div className="col-lg-8 mt-5 border-top pt-2">
        <div className="cardd row p-3  ">

        <div className="package-img my-auto col-md-4">
            <LazyLoadImage
                alt=''
                effect="blur"
                wrapperProps={{
                    // If you need to, you can tweak the effect transition using the wrapper style.
                    style: {transitionDelay: "1s"},
                }}
                src={props.card2_image} 
                className='shadow img-fluid'
                />
                {/* <img src= alt="" className='img-fluid shadow'></img> */}
            </div>
            <div className='col-md-8 mt-md-0 mt-3 p-3'>
                <div className='d-flex justify-content-between align-items-start'>
                    <h4 className='fw-bold w-75'>
                        {props.card2_title}  
                    </h4>
                    <span className='px-3 py-1 w-25 text-white fs-12 bg-yellow rounded text-center'>
                        {props.card2_days}
                    </span>
                </div>
                <p className="fs-12 text-secondary">{props.card1_availability}</p>
                <p className="fs-14 fw-bold text-orange">{props.price2}</p>
                <p className='fs-14'>{props.description2}</p>
                <div className='row mt-3'>
                    <div className="col-7">
                        <Link to="/contact" className="btn-1 p-2 fs-14 px-md-4 px-3">Book Now</Link>
                    </div>
                    <div className="col-5 text-end">
                        {props.pdf2 != null ? (<a href={props.pdf2} target='_blank' className="btn-1 p-2 fs-14 px-md-4 px-3" rel='noreferrer'>Explore</a>) : (<span  target='_blank' className="btn-1 p-2 fs-14 px-md-4 px-3 disabled-link" rel='noreferrer'>Explore</span>)}
                    </div>
                </div>
            </div>
            </div>
        </div>

        <div className="col-lg-8 mt-5 border-top pt-2">
        <div className="cardd row p-3 ">

        <div className="package-img my-auto  col-md-4" >
            <LazyLoadImage
                alt=''
                effect="blur"
                wrapperProps={{
                    // If you need to, you can tweak the effect transition using the wrapper style.
                    style: {transitionDelay: "1s"},
                }}
                src={props.card3_image} 
                className='shadow img-fluid'
                />
                {/* <img src= alt="" className='img-fluid shadow'></img> */}
            </div>
            <div className='col-md-8 mt-md-0 mt-3 p-3'>
                <div className='d-flex justify-content-between align-items-start'>
                    <h4 className='fw-bold w-75'>
                        {props.card3_title}  
                    </h4>
                    <span className='px-3 py-1 w-25 text-white fs-12 bg-yellow rounded text-center'>
                        {props.card3_days}
                    </span>
                </div>
                <p className="fs-12 text-secondary">{props.card3_availability}</p>
                <p className="fs-14 fw-bold text-orange">{props.price3}</p>
                <p className='fs-14'>{props.description3}</p>
                <div className='row mt-3'>
                    <div className="col-7">
                        <Link to="/contact" className="btn-1 p-2 fs-14 px-md-4 px-3">Book Now</Link>
                    </div>
                    <div className="col-5 text-end">
                        {props.pdf3 != null ? (<a href={props.pdf3} target='_blank' className="btn-1 p-2 fs-14 px-md-4 px-3" rel='noreferrer'>Explore</a>) : (<span  target='_blank' className="btn-1 p-2 fs-14 px-md-4 px-3 disabled-link" rel='noreferrer'>Explore</span>)}
                    </div>
                </div>
            </div>
            </div>
        </div>

      

      </div>
    </>
  )
}

import React, { useRef} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import Packages from './Packages';
import Places from './Places';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import { Link } from 'react-router-dom';
import { EffectFade, Autoplay, Navigation } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';

export default function Home() {
  const navigate = useNavigate();
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    var overlay = document.querySelector('.overlay');
    var spinner = document.querySelector('.spinner-border');
    overlay.style.visibility = 'visible';
    overlay.style.display = 'flex';
    spinner.style.display = 'block';
        

    emailjs.sendForm('service_astjhkk', 'template_ezwf7fq', form.current, 'G5epzNHERbqK1eCUt')
      .then((result) => {
        form.current.from_name.value="";
        form.current.from_email.value="";
        form.current.message.value="";
        alert('Email has been sent successfully');
        overlay.style.visibility = 'hidden';
        overlay.style.display = 'block';
        spinner.style.display = 'none';

      }, (error) => {
          alert('An error occured' + error.text);
          overlay.style.visibility = 'hidden';
          overlay.style.display = 'block';
          spinner.style.display = 'none';
      });
  };

  const findpkg = () => {
    const location = document.getElementById('location').value;
    const type = document.getElementById('type').value;
    const keyword = document.getElementById('keyword').value;

    if ((location <= 4 && type === 1) || keyword) {
      navigate('/North');
    } else if ((location > 4 && type === 2) || keyword) {
      navigate('/Umrah');
    } else if (location > 4 && (type === 1 || keyword)) {
      navigate('/Umrah');
    } else if ((location <= 4 && type === 2) || keyword) {
      navigate('/North');
    } else {
      navigate('/North');
    }
  };


  return (
    <>
    <section className='banner'>
       <div className="row w-100 gx-0">
         <div className="col-lg-1 my-auto mx-auto d-lg-block d-none">
            <div className='d-flex align-item-center justify-content-center vertical-text'>
             
              <ul className='p-0 list-unstyled d-flex icons-ul'>
                 <li>
                    <a href="https://www.facebook.com/login/?next=https%3A%2F%2Fwww.facebook.com%2Fmpatravelsandtours%2F">
                         <img src="static/images/fb.webp" alt="" className="" width={'30px'}></img>
                    </a>
                </li>
                 <li>
                    <a href="https://www.instagram.com/mpatravelsandtours/">
                      <img src="static/images/insta.webp" alt="" className="" width={'30px'}></img>
                    </a>
                </li>
                 <li>
                    <a href="https://www.tiktok.com/@mpatravelsandtours">
                      <img src="static/images/tiktok.webp" alt="" className="" width={'30px'}></img>
                    </a>
                </li>
              </ul>
              <p>@MPATravelsAndTours</p>
            </div>
         </div>
         <div className="col-lg-11">
         <div className="my-swiper-container">
         <Swiper navigation={true} modules={[EffectFade, Autoplay, Navigation]} effect={'fade'} autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }} slidesPerView={1} className="mySwiper">
          <SwiperSlide>
            <div className="slide-img padding">
                <div className="container text-lg-start text-center">
                  <h2 className="fw-bold text-orange">
                    Northern Pakistan
                  </h2>
                  <h1 className="fw-bold text-white mb-0">
                    Where Nature's
                  </h1>
                  <h1 className="fw-bold text-white">
                    Beauty Unfolds
                  </h1>
                  <p className='text-white mb-0'>
                    Explore the stunning beauty of Northern  
                  </p>
                  <p className='text-white'>
                  Pakistan with our Unforgettable tours!
                  </p>
                  <div className='mt-5'>
                    <Link to="/contact" className='btn-1'>
                        Book Now
                    </Link>
                    <Link to="/North" className='btn-2 mx-2'>
                        More Info
                    </Link>
                  </div>
                </div>
            </div>
          </SwiperSlide>
         
          <SwiperSlide>
            <div className="slide-img slide-img2 padding">
            <div className="container text-md-start text-center">
                <h2 className="fw-bold text-orange">
                    Special Discount
                  </h2>
                  <h1 className="fw-bold text-white mb-0">
                    Umrah Packages
                  </h1>
                  
                  <p className='text-white'>
                    Plan Your Umrah with MPA Travel & Tours
                  </p>
                 
                  <div className='mt-5'>
                    <Link to="/contact" className='btn-1'>
                        Book Now
                    </Link>
                    <Link to="/Umrah" className='btn-2 mx-2'>
                        More Info
                    </Link>
                  </div>
                </div>
            </div>
          </SwiperSlide>
       
          
        </Swiper>


    </div>
      <div className='filter-box' data-aos="fade-up">
        <div className="d-flex px-4 mx-auto flex-md-row flex-column">

        <div class=" mx-auto">
          <select name="location" id="location" className='form-select' placeholder="All location">
            <option disabled selected>All location</option>
            <option value="1">Hunza</option>
            <option value="2">Skardu</option>
            <option value="3">Kashmir</option>
            <option value="4">Swat</option>
            <option value="5">Makkah</option>
            <option value="6">Madina</option>
          </select>
        </div>

        <div class=" mx-auto">
          <select name="" id="type" className='form-select' placeholder='Trip Types'>
            <option selected disabled>Trip Types</option>
            <option value="1">North Pakistan</option>
            <option value="2">Hajj & Umrah</option>
          </select>
        </div>

        <div class=" mx-auto">
          <input type="text" className='form-control' placeholder='Keyword' id="keyword" />
        </div>
        
        </div>

        <div class="btn-box" onClick={findpkg}>
           <Link className='btn-3 text-white fs-14'>
            Find Packages
           </Link>
        </div>
      </div>
         </div>
       </div>
    </section>

    <section className="padding ">
        <div className="container">
         <div className="d-flex justify-content-between align-items-center flex-lg-row flex-column w-100">
          <div>
          <h1 className="fw-bold text-lg-start text-center">
            <span data-aos="fade-up">T</span><span data-aos="fade-up" data-aos-delay="50">r</span><span data-aos="fade-up" data-aos-delay="100">i</span><span data-aos="fade-up" data-aos-delay="150">p</span> <span data-aos="fade-up" data-aos-delay="200">H</span><span data-aos="fade-up" data-aos-delay="250">i</span><span data-aos="fade-up" data-aos-delay="300">g</span><span data-aos="fade-up" data-aos-delay="350">h</span><span data-aos="fade-up" data-aos-delay="400">l</span><span data-aos="fade-up" data-aos-delay="450">i</span><span data-aos="fade-up" data-aos-delay="500">g</span><span data-aos="fade-up" data-aos-delay="550">h</span><span data-aos="fade-up" data-aos-delay="600">t</span>
          </h1>
          <p className='text-lg-start text-center'>Just choose your destination, and we'll handle the rest!</p>
          </div>

          <div className='mt-lg-0 mt-2'>
            <Link to="/North" className='btn-3'>View all</Link>
          </div>
         </div>
            <Packages 
            card1_image="static/images/pkg1.webp"
            card1_title="Summer Special Naran Kaghan Trip"
            card1_days="7 Days"
            card1_availability="2 Pakages Available"
            pdf1="static/images/7daysNaran.pdf"

            card2_image="static/images/pkg2.webp"
            card2_title="Summer Special Hunza & Skardu"
            card2_days="12 Days"
            card2_availability="1 Pakages Available"
            pdf2="static/images/hunza&skardu.pdf"


            card3_image="static/images/pkg3.webp"
            card3_title="Swat & Kaghan From Hyderabad"
            card3_days="12 Days"
            card3_availability="5 Pakages Available"
            pdf3="static/images/swat&naran.pdf"
            
            />
        </div>

    </section>


    <section className="padding">
        <div className="container">
         <div className="d-flex justify-content-between align-items-center flex-lg-row flex-column w-100">
            <div>
            <h1 className="fw-bold text-md-start text-center">
              Popular Destination
            </h1>
            <p className='text-md-start text-center'>Just select where you want to go, we take care of rest!</p>
            </div>
            <div>
              <Link to="/services" className='btn-3'>View all</Link>
            </div>
         </div>

            <Places
              image1_url = "static/images/5.webp"
              title1 = "Makkah"
              availability1 = "2 Packages Available"

              image2_url = "static/images/madina.png"
              title2 = "Madina"
              availability2 = "2 Packages Available"
            />
            <Places
              image1_url = "static/images/4.webp"
              title1 = "Hunza-Valley"
              availability1 = "3 Packages Available"

              image2_url = "static/images/6.webp"
              title2 = "Naltar-Valley"
              availability2 = "2 Packages Available"
            />
        </div>

    </section>


    <section className="padding">
      <div className="row w-100 gx-0">
          <div className="col-md-1">

          </div>
          <div className="col-md-11 px-md-0">
            <div className="my-swiper-container2 padding px-4">
                <div className="container text-md-start text-center">
                <h2 className="fw-bold text-orange" data-aos="fade-up">
                    Special Discount
                  </h2>
                  <h1 className="fw-bold text-white mb-0" data-aos="fade-up" data-aos-delay="100">
                    Umrah Packages
                  </h1>
                  
                  <p className='text-white' data-aos="fade-up" data-aos-delay="200">
                    Plan Your Umrah with MPA Travel & Tours
                  </p>
                 
                  <div className='mt-5' data-aos="fade-up" data-aos-delay="300">
                    <Link to="/contact" className='btn-1'>
                        Book Now
                    </Link>
                    <Link to="/Umrah" className='btn-2 mx-2'>
                        More Info
                    </Link>
                  </div>
                </div>
            </div>
          </div>
      </div>
    </section>



    <section className="padding">
        <div className="container">
         <div className="d-flex justify-content-between align-items-center flex-lg-row flex-column w-100">
            <div>
            <h1 className="fw-bold text-md-start text-center" data-aos="fade-up">
            UMRAH Package's
          </h1>
          <p className="text-md-start text-center" data-aos="fade-up" data-aos-delay="100">Limited time offer</p>
            </div>
            <div>
              <Link to="/Umrah" className="btn-3">View all</Link>
            </div>
         </div>
          <Packages 
          card1_image="static/images/umrah1.webp"
          card1_title="Muharram Umrah Package"
          card1_days="20 Days"
          card1_availability="2 Pakages Available"

          card2_image="static/images/umrah2.webp"
          card2_title="Rabi ul Awwal Umrah Package"
          card2_days="21 Days"
          card2_availability="1 Pakages Available"

          card3_image="static/images/umrah3.webp"
          card3_title="Muharram Umrah Package"
          card3_days="20 Days"
          card3_availability="5 Pakages Available"
          
          />
        </div>

    </section>

    <section className="padding">
      <div className="row w-100 gx-0">
          <div className="col-md-1">

          </div>
          <div className="col-md-11">
            <div className="my-swiper-container3 padding">
                <div className="container">
                    <div className="row px-4">
                      <div className="col-md-6 my-auto mb-md-auto mb-3">
                        <h1 className="fw-bold text-white text-md-start text-center" data-aos="fade-up">
                          Get the best deals on your next trip,
                        </h1>
                      </div>
                      <div className="col-md-6 text-md-start text-center">
                        <form ref={form} onSubmit={sendEmail}>
                          <div className='w-md-75 w-100 '>
                            <input type="text" id="user_name" className='form-control mb-2' name='from_name' placeholder='Name' data-aos="fade-up" required></input>
                            <input type="email" id="user_email" className='form-control mb-2 ' name='from_email' data-aos="fade-up" required data-aos-delay='200' placeholder='Email'></input>
                            <textarea type="text" name='message' id="message" data-aos="fade-up" data-aos-delay="300" className='form-control mb-2 ' required placeholder='Message'></textarea>
                            <div className='mt-4' data-aos="fade-up" data-aos-delay="400">
                              <input type='text' name='from_platform' className=''  hidden value="MPA Travel"></input>
                              <button type='submit' className='btn-1 btn'>Send Message</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                </div>
            </div>
          </div>
      </div>
    </section>


    </>
  )
}

import React from 'react'
import { Link } from 'react-router-dom'

export default function Places(props) {
  return (
    <>
        <div className="row mt-3 ">
            
            <div className="col-md-6 mb-md-0 mb-3"data-aos="fade-up"> 
                <div className="place-bg-img place-bg-img1 object-fit-cover" style={{"background": `linear-gradient(to right , rgba(26,14,56,0.9),rgba(26,14,56,0)), url(${props.image1_url})`, "backgroundSize":'cover'}}>
                    <h3 className='text-white fw-bold mb-1 mt-5'>{props.title1}</h3>
                    <p className="fs-12 text-light">{props.availability1}</p>
                    <div>
                        <Link to="/services" className='btn-1'>More Info</Link>
                    </div>
                </div>
            </div>

            <div className="col-md-6 mb-md-0" >
                <div className="place-bg-img place-bg-img2 object-fit-cover" data-aos="fade-up" data-aos-delay="100" style={{"background": `linear-gradient(to right , rgba(26,14,56,0.9),rgba(26,14,56,0)), url(${props.image2_url})`, "backgroundSize":'cover'}}>
                    <h3 className='text-white fw-bold mb-1 mt-5'>{props.title2}</h3>
                    <p className="fs-12 text-light">{props.availability2}</p>
                    <div>
                    <Link to="/services" className='btn-1'>More Info</Link>

                    </div>
                </div>
            </div>

        </div> 
    </>
  )
}

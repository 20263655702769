import React, { useRef } from 'react'
import emailjs from 'emailjs-com';

export default function TripPlan() {
    
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        var overlay = document.querySelector('.overlay');
        var spinner = document.querySelector('.spinner-border');
        overlay.style.visibility = 'visible';
        overlay.style.display = 'flex';
        spinner.style.display = 'block';
            

        emailjs.sendForm('service_astjhkk', 'template_ezwf7fq', form.current, 'G5epzNHERbqK1eCUt')
        .then((result) => {
            form.current.from_name.value="";
            form.current.from_email.value="";
            form.current.from_phone.value="";
            form.current.from_location.value="";
            form.current.from_days.value="";
            form.current.from_tour.value="";
            form.current.message.value="";
            alert('Email has been sent successfully');
            overlay.style.visibility = 'hidden';
            overlay.style.display = 'block';
            spinner.style.display = 'none';

        }, (error) => {
            alert('An error occured' + error.text);
            overlay.style.visibility = 'hidden';
            overlay.style.display = 'block';
            spinner.style.display = 'none';
        });
    };

  return (
    <>
      <section className="padding bg-trip d-flex justify-content-center align-items-center">
        <div className="container ">
                <h1 className="text-white text-center">
                    Plan My Trip
                </h1>
           
        </div>
      </section>

      <section className="padding position">
        <div className="container">
        <div>
            <form ref={form} onSubmit={sendEmail}  className=' bg-light border plan-form'>
                <div>
                    <label htmlFor="name">Full Name <span className='text-danger'>*</span></label>
                    <div className='mt-2'>
                        <input type="text" id="name" name='from_name' required className='form-control' placeholder='eg: Johns'></input>
                    </div>
                </div>

                <div className='mt-3'>
                    <label htmlFor="email">Email Address <span className='text-danger'>*</span></label>
                    <div className='mt-2'>
                        <input type="email" id="email" name='from_email' required className='form-control' placeholder='Email Address'></input>
                    </div>
                </div>

                <div className='mt-3'>
                    <label htmlFor="tel">Contact Number <span className='text-danger'>*</span></label>
                    <div className='mt-2'>
                        <input type="tel" id="tel" required className='form-control' name='from_phone' placeholder='03352672863'></input>
                    </div>
                </div>

                <div className='mt-3'>
                    <label htmlFor="num">Total Days <span className='text-danger'>*</span></label>
                    <div className='mt-2'>
                        <input type="number" id="num" required name='from_days' className='form-control' placeholder='9 days'></input>
                    </div>
                </div>

                <div className='mt-3'>
                    <label htmlFor="location">Base Location <span className='text-danger'>*</span></label>
                    <div className='mt-2'>
                        <input type="text" id="location" required name='from_location' className='form-control' placeholder='Karachi'></input>
                    </div>
                </div>

                <div className='mt-3'>
                    <label htmlFor="group">Group Size <span className='text-danger'>*</span></label>
                    <div className='mt-2'>
                        <textarea type="text" id="group" className='form-control' placeholder='2  Adults 1 child'></textarea>
                    </div>
                </div>

                <div className='mt-3'>
                    <label htmlFor="tour">Tour Destination(s) <span className='text-danger'>*</span></label>
                    <div className='mt-2'>
                        <textarea type="text" id="tour" name='from_tour' required className='form-control' placeholder='Hunza, Skardu, Chitral'></textarea>
                    </div>
                </div>

                <div className='mt-3'>
                    <label htmlFor="comments">Additional Comments <span className='text-danger'>*</span></label>
                    <div className='mt-2'>
                        <textarea type="text" id="comments" name='message' className='form-control' placeholder='Example: Please organise a BBQ bonfire on one of the days.'></textarea>
                    </div>
                </div>

                <div className="mt-3">
                    <input type="text" className='' hidden value="MPA Travel" name='from_platform' ></input>
                    <button type="submit" className='btn-3 btn' > Submit</button>
                </div>
            </form>
            </div>
        </div>
      </section>

      
    </>
  )
}

import React, { useRef } from 'react'
import emailjs from 'emailjs-com';

export default function Contact() {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        var overlay = document.querySelector('.overlay');
        var spinner = document.querySelector('.spinner-border');
        overlay.style.visibility = 'visible';
        overlay.style.display = 'flex';
        spinner.style.display = 'block';
        
        emailjs.sendForm('service_astjhkk', 'template_ezwf7fq', form.current, 'G5epzNHERbqK1eCUt')
        .then((result) => {
            form.current.from_name.value="";
            form.current.from_email.value="";
            form.current.from_phone.value="";
            form.current.message.value="";
            alert('Email has been sent successfully');
            overlay.style.visibility = 'hidden';
            overlay.style.display = 'block';
            spinner.style.display = 'none';

        }, (error) => {
            alert('An error occured' + error.text);
            overlay.style.visibility = 'hidden';
            overlay.style.display = 'block';
            spinner.style.display = 'none';
        
        });
    };

  return (
    <>
      <section className="padding bg-contact d-flex justify-content-center align-items-center">
            <div className="container">
                <h1 className="text-white text-center">
                    Contact Us
                </h1>
            </div>
      </section>






      <section className="padding position2">
        <div className="container">
        <div>
            <form ref={form} onSubmit={sendEmail} className=' bg-light border plan-form'>
              <div className="row">
                <div className="col-md-6 my-auto">
                <div>
                    <label htmlFor="name">Full Name <span className='text-danger'>*</span></label>
                    <div className='mt-2'>
                        <input type="text" id="name" name='from_name' className='form-control' placeholder='eg: Johns' required></input>
                    </div>
                </div>

                <div className='mt-3'>
                    <label htmlFor="email">Email Address <span className='text-danger'>*</span></label>
                    <div className='mt-2'>
                        <input type="email" id="email" name='from_email' className='form-control' placeholder='Email Address' required></input>
                    </div>
                </div>

                <div className='mt-3'>
                    <label htmlFor="tel">Contact Number <span className='text-danger'>*</span></label>
                    <div className='mt-2'>
                        <input type="tel" id="tel" name='from_phone' className='form-control' placeholder='03352672863' required></input>
                    </div>
                </div>

                <div className='mt-3'>
                    <label htmlFor="msg">Message <span className='text-danger'>*</span></label>
                    <div className='mt-2'>
                        <textarea name="message" className='form-control' placeholder='message' id="msg" required></textarea>
                    </div>
                </div>

              
                <div className="mt-3">
                    <input type="text" hidden value='MPA Travel' name="from_platform"></input>
                    <button type="submit" className='btn-3 btn' > Submit</button>
                </div>
                </div>
                <div className="col-md-6 mt-md-0 mt-3">
                    <h4 className="fw-bold">
                        Contact Info
                    </h4>
                    <h6 className='fw-bold'>
                        Address
                    </h6>
                    <p className="mt-3 text-secondary">
                    First Floor Raeesa Apartment Unit No.07 Near Maajee Hospital Latifabad Hyderabad
                    </p>

                    <h6 className='fw-bold mt-3'>
                        Email Us
                    </h6>
                    <a href='mailto:MPAtravelsAndTours@gmail.com' className="mt-3 text-secondary">
                    MPAtravelsAndTours@gmail.com
                    </a>
                    <h6 className='fw-bold mt-3'>
                        Call Us
                    </h6>
                    <a href='https://api.whatsapp.com/send/?phone=%2B923367287283&text&type=phone_number&app_absent=0' className="mt-3 text-secondary">
                    (+92) 336-7287283
                    </a>

                    <h6 className='fw-bold mt-3'>
                        Follow Us
                    </h6>
                    <p className='mb-2' >
                            <div>
                            <a href="https://www.facebook.com/login/?next=https%3A%2F%2Fwww.facebook.com%2Fmpatravelsandtours%2F" target='_blank' rel='noreferrer'>
                                <img src="static/images/fb.webp" alt="" className='img-fluid' width={'35px'} ></img>
                            </a>
                            <a href="https://www.instagram.com/mpatravelsandtours/">
                                <img src="static/images/insta.webp" alt="" className='img-fluid' width={'35px'} target='_blank' rel='noreferrer'></img>
                            </a>
                            <a href="https://www.tiktok.com/@mpatravelsandtours">
                                <img src="static/images/tiktok.webp" alt="" className='img-fluid' width={'35px'} target='_blank' rel='noreferrer'></img>
                            </a>
                            <a href="mailto:MPAtravelsAndTours@gmail.com" target='_blank' rel='noreferrer' className='text-secondary'>
                                @MPATravelsAndTours
                            </a>
                            </div>
                        </p>
              </div>
              </div>
             
            </form>
            </div>
        </div>
      </section>

    </>
  )
}

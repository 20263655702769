import React, { useEffect } from 'react'
import './App.css';
import Home from './Components/Home';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import North from './Components/North';
import Umrah from './Components/Umrah';
import TripPlan from './Components/TripPlan';
import About from './Components/About';
import Contact from './Components/Contact';
import Services from './Components/Services';
import { useLocation } from 'react-router-dom';

function App() {
  useEffect(() => {
    AOS.init({
      delay:500,
      duration: 1000, // animation duration in milliseconds
      // once: true, // whether animation should happen only once - while scrolling down
    });
  }, []);

  const ScrollToTop = () => {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: 'instant'
      });
    }, [pathname]);
  
    return null;
  };


  return (
    <>
    <Router>
      <ScrollToTop/>
      <Navbar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/North" element={<North />} />
          <Route path="/Umrah" element={<Umrah />} />
          <Route path="/PlanMyTrip" element={<TripPlan />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>  
      <Footer/>
    </Router>
    </>
  );
}

export default App;

import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';

export default function Packages(props) {
    
  return (
    <>
      <div className="row mt-4 gx-4">
        
        <div className="col-lg-4 col-md-6 mb-lg-0 mb-3" id='package'>
            <div className="package-img mb-3" data-aos="fade-up">
            <LazyLoadImage
                alt=''
                effect="blur"
                wrapperProps={{
                    // If you need to, you can tweak the effect transition using the wrapper style.
                    style: {transitionDelay: "1s"},
                }}
                src={props.card1_image} 
                className='shadow img-fluid'
                />
                {/* <img src= alt="" className='img-fluid shadow'></img> */}
            </div>
            <div >
                <div className='d-flex justify-content-between align-items-start'>
                    <h4 className='fw-bold w-75'>
                        {props.card1_title}  
                    </h4>
                    <span className='px-3 py-1 w-25 text-white fs-12 bg-yellow rounded text-center'>
                        {props.card1_days}
                    </span>
                </div>
                <p className="fs-12 text-secondary">{props.card1_availability}</p>
                <div className='row'>
                    <div className="col-6">
                        <Link to="/contact" className='btn-1 p-2 fs-14 px-3'>
                            Book Now
                        </Link>
                    </div>
                    <div className="col-6 text-end">
                    { props.pdf1 != null ? (<a href={props.pdf1} target="_blank" rel='noreferrer' className='btn-1 p-2 fs-14 px-3'>
                            Explore
                        </a>):(<span  className='btn-1 p-2 fs-14 px-3 disabled-link'>
                            Explore
                        </span>)}
                    </div>
                </div>
            </div>
        </div>

        <div className="col-lg-4 col-md-6 mb-lg-0 mb-3" id='package'>
            <div className="package-img mb-3" data-aos="fade-up" data-aos-delay="100"> 
                <LazyLoadImage
                    alt=''
                    effect="blur"
                    wrapperProps={{
                        // If you need to, you can tweak the effect transition using the wrapper style.
                        style: {transitionDelay: "1s"},
                    }}
                    src={props.card2_image}
                    className='shadow img-fluid' />
            </div>
            <div >
                <div className='d-flex justify-content-between align-items-start'>
                    <h4 className='fw-bold w-75'>
                        {props.card2_title} 
                    </h4>
                    <span className='px-3 py-1 w-25 text-white fs-12 bg-yellow rounded text-center'>
                        {props.card2_days}
                    </span>
                </div>
                <p className="fs-12 text-secondary">{props.card2_availability}</p>
                <div className='row'>
                    <div className="col-6">
                        <Link to="/contact" className='btn-1 p-2 fs-14 px-3'>
                            Book Now
                        </Link>
                    </div>
                    <div className="col-6 text-end">
                        { props.pdf2 != null ? (<a href={props.pdf2} target="_blank" rel='noreferrer' className='btn-1 p-2 fs-14 px-3'>
                            Explore
                        </a>):(<span  className='btn-1 p-2 fs-14 px-3 disabled-link'>
                            Explore
                        </span>)}
                    </div>
                </div>
            </div>
        </div>



        <div className="col-lg-4 col-md-6 mb-lg-0 mb-3" id="package">
            <div className="package-img mb-3" data-aos="fade-up" data-aos-delay="200">
            <LazyLoadImage
                alt=''
                effect="blur"
                wrapperProps={{
                    // If you need to, you can tweak the effect transition using the wrapper style.
                    style: {transitionDelay: "1s"},
                }}
                src={props.card3_image}
                className='shadow img-fluid'
                />
            </div>
            <div >
                <div className='d-flex justify-content-between align-items-start'>
                    <h4 className='fw-bold w-75'>
                        {props.card3_title} 
                    </h4>
                    <span className='px-3 py-1 w-25 text-white fs-12 bg-yellow rounded text-center'>
                        {props.card3_days}
                    </span>
                </div>
                <p className="fs-12 text-secondary">{props.card3_availability}</p>
                <div className='row'>
                    <div className="col-6">
                        <Link to="/contact" className='btn-1 p-2 fs-14 px-3'>
                            Book Now
                        </Link>
                    </div>
                    <div className="col-6 text-end">
                    { props.pdf3 != null ? (<a href={props.pdf3} target="_blank" rel='noreferrer' className='btn-1 p-2 fs-14 px-3'>
                            Explore
                        </a>):(<span className='btn-1 p-2 fs-14 px-3 disabled-link'>
                            Explore
                        </span>)}
                    </div>
                </div>
            </div>
        </div>

      </div>
    </>
  )
}

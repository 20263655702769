import React from 'react'

export default function About() {
  return (
    <>
       <section className="padding3 bg-about d-flex justify-content-center align-items-center">
        <div className="container ">
                <h1 className="text-white text-center mb-3">
                    About Us
                </h1>
                <p className='text-center text-white mb-0'>Discover the world with our travel agency, offering bespoke journeys to breathtaking destinations.</p>
                <p className='text-center text-white mb-0'> Let us craft your perfect adventure with expert guidance and personalized service.</p>
        </div>
      </section>

      <section className="padding">
        <div className="container">
            <div className="row p-4 shadow rounded">
                <div className="col-md-6 order-md-1 order-2 mt-md-0 mt-3 text-md-start text-center">
                    <h4 className="text-blue">
                        Get to Know MPA Travels and Tours:
                    </h4>
                    <p className=''>
                    Come join us at MPA Travels and Tours, where we're all about making your travel experience special. We love showing off the real beauty of Pakistan, mixing in great service with genuine moments. Every trip with us is a chance to see and feel the heart of Pakistan, creating memories that'll stick with you.
                    </p>

                    <h4 className=" text-blue">
                        Awesome Adventures, Made Just for You:
                    </h4>
                    <p className=''>
                    MPA is here to make your travel dreams in Pakistan come true. We've got a team of pros working hard to plan out trips that show off Pakistan's cool spots. If you're into bustling markets, peaceful valleys, or historic places, MPA Travels and Tours is the way to go. We'll guide you through Pakistan's best-kept secrets hassle-free.
                    </p>
                </div>

                <div className="col-md-6 order-md-2 order-1 text-md-start text-center">
                    <div>
                        <img src="static/images/travel1-3.webp" alt="" className="img-fluid rounded shadow about-img"></img>
                    
                    </div>
                </div>

            

              
            </div>

            <div className="row">
            <div className="col-md-6 my-auto mt-4 text-md-start text-center">
                    <div>
                        <img src="static/images/mountain.webp" alt="" className="img-fluid rounded shadow about-img"></img>
                    
                    </div>
                </div>


                <div className="col-md-6 my-auto text-md-start text-center">
                    <h4 className="text-blue">
                    Your Unique Journey with MPA:
                    </h4>
                    <p className='text-justify'>
                    At MPA Travels and Tours, we believe everyone's trip should be one-of-a-kind. We're all about making your journey special, mixing in local culture, breathtaking views, and genuine experiences. With us, you're not just a traveler; you're part of a personalized adventure where every stop is a new chapter in your story.
                    </p>
                </div>
            </div>
        </div>
      </section>
    </>
  )
}
